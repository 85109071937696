const backToTopBtnEl = document.querySelector('.back-to-top');

try {
  if (backToTopBtnEl) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > window.innerHeight * 1.5) {
        backToTopBtnEl.classList.add('back-to-top--active');
      } else {
        backToTopBtnEl.classList.remove('back-to-top--active');
      }
    });
  }
} catch (error) {
  console.log(error);
}
document.addEventListener('DOMContentLoaded', function() {
  function getQueryParam(param) {
    var queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  }
  var sector = getQueryParam('sector');
  if (sector !== null && !isNaN(sector)) {
    
    var targetElement = document.querySelector('.employeeListComponent');
    if (targetElement) {
       var elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
       window.scrollTo({ top: elementPosition - 100, behavior: 'smooth' });
    } else {
      targetElement = document.querySelector('.investmentListComponent');
      if (targetElement) {
        var elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: elementPosition - 100, behavior: 'smooth' });
     }
    }
  }
});