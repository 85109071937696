import getUrlParameter from './get-url-parameter.js';

const height = (el) => el.getBoundingClientRect().height;

const lg = window.matchMedia('(min-width: 1024px)');
const lang = window.lang;

const noexpander = getUrlParameter('noexpander');

const introEl = document.querySelector('.investment-detail-intro .intro');
const introTitleEl = document.querySelector('.investment-detail-intro .intro__title');
const introTextEl = document.querySelector('.investment-detail-intro .intro__text');
const infoEl = document.querySelector('.investment-info');
const hasNeededElements = introEl && introTitleEl && introTextEl && infoEl;

try {
  if (noexpander != 1 && lg.matches && hasNeededElements) {
    if (height(introEl) > height(infoEl) * 1.3) {
      introEl.classList.add('intro--expander');

      let readMoreText = 'read more';
      if (lang == 'de') {
        readMoreText = 'mehr lesen';
      } else if (lang == 'fr') {
        readMoreText = 'en savoir plus';
      }

      // construct button
      const readMoreBtnEl = document.createElement('div');
      const readMoreBtnText = document.createTextNode(readMoreText);
      readMoreBtnEl.classList.add('button', 'button--bar', 'intro--expander--button');
      readMoreBtnEl.appendChild(readMoreBtnText);
      introTextEl.appendChild(readMoreBtnEl);

      introEl.style.height = height(infoEl) + 'px';

      readMoreBtnEl.addEventListener('click', () => {
        const fullHeight = height(introTitleEl) + height(introTextEl) + 70;
        introEl.classList.add('intro--expanded');
        introEl.style.height = fullHeight + 'px';
        setTimeout(() => {
          introEl.style.height = 'auto';
        }, 1000);
      });
    }
  }
} catch (error) {
  console.log(error);
}
