const mapEls = document.querySelectorAll('.europe-map-home');
const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      const noActiveClass = !entry.target.classList.contains('europe-map-home--animated');
      if (entry.isIntersecting) {
        if (noActiveClass) {
          entry.target.classList.add('europe-map-home--animated');
          observer.unobserve(entry.target);
        }
      }
    });
  },
  { rootMargin: '-30% 0px -30% 0px', threshold: 0 }
);

try {
  mapEls.forEach((mapEl) => {
    observer.observe(mapEl);
  });
} catch (error) {
  console.log(error);
}
