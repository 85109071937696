const navEl = document.querySelector('.sub-menu');
const navBarEl = document.querySelector('.submenu__active-bar');
let navActiveEl = document.querySelector('.sub-menu__list__item--active');
const navItemEls = document.querySelectorAll('.sub-menu__list__item');

const positionBarOn = (activeItem) => {
  if (navActiveEl) {
    const itemWidth = activeItem.getBoundingClientRect().width;
    const itemOffsetLeft = activeItem.getBoundingClientRect().left;
    const navOffsetLeft = navEl.getBoundingClientRect().left;
    navBarEl.style.left = itemOffsetLeft - navOffsetLeft + 'px';
    navBarEl.style.width = itemWidth + 'px';
  }
};

try {
  if (navEl && navBarEl) {
    navItemEls.forEach((item) => {
      const isAnchor = item.querySelector('a').getAttribute('href').charAt(0) == '#';
      if (isAnchor) {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          navItemEls.forEach((i) => i.classList.remove('sub-menu__list__item--active'));
          item.classList.add('sub-menu__list__item--active');
          navActiveEl = item;
          positionBarOn(navActiveEl);
        });
      }
      item.addEventListener('mouseover', () => positionBarOn(item));
      item.addEventListener('mouseleave', () => positionBarOn(navActiveEl));
    });

    if (navActiveEl) {
      setTimeout(() => {
        positionBarOn(navActiveEl);
      }, 500);
    }

    window.addEventListener('resize', () => positionBarOn(navActiveEl));
  }
} catch (error) {
  console.log(error);
}
