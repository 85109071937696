const contactNavELs = document.querySelectorAll('.sub-menu__list .locationurl');
const activeContactNavEL = document.querySelector('.sub-menu__list__item--active');
const activeLocationEl = document.querySelector('.sub-menu__list__item--active .locationurl');
const contactInfoEls = document.querySelectorAll('.contact-us-item');

try {
  const names = {
    muenchen: 'munich',
    zuerich: 'zurich',
    londres: 'london',
  };

  Object.keys(names).forEach((nameKey) => {
    contactInfoEls.forEach((contactInfoEl) => {
      if (contactInfoEl.id === nameKey) {
        contactInfoEl.id = names[nameKey];
      }
    });
  });

  if (window.location.pathname === '/contact') {
    history.pushState(null, null, 'contact/');
  }
  if (window.location.pathname === '/standorte') {
    history.pushState(null, null, 'standorte/');
  }

  if (activeContactNavEL && activeLocationEl) {
    let href = activeLocationEl.getAttribute('data-location');

    const assignHref = (href) => {
      let keyvisualHref = href;
      const keyvisualImgEl = document.querySelector('.keyvisual--contact img');

      if (href && href != '') {
        history.pushState(null, null, href);
      }
      const newCurrentContactInfoEl = document.querySelector('#' + keyvisualHref);
      if (newCurrentContactInfoEl) {
        const oldCurrentContactInfoEls = document.querySelectorAll('.contact-us-item.current');
        oldCurrentContactInfoEls.forEach((oldCurrentContactInfoEl) =>
          oldCurrentContactInfoEl.classList.remove('current')
        );
        newCurrentContactInfoEl.classList.add('current');
      }

      if (keyvisualImgEl) {
        Object.keys(names).forEach((nameKey) => {
          if (keyvisualHref === nameKey) {
            keyvisualHref = names[nameKey];
          }
        });

        const regex = new RegExp('#LOCATION#', 'g');
        const newSrc = keyvisualImgEl
          .getAttribute('data-js-template-src')
          .replace(regex, keyvisualHref);
        const newSrcset = keyvisualImgEl
          .getAttribute('data-js-template-srcset')
          .replace(regex, keyvisualHref);
        keyvisualImgEl.setAttribute('src', newSrc);
        keyvisualImgEl.setAttribute('srcset', newSrcset);
      }
    };

    assignHref(href);

    contactNavELs.forEach((contactNavEL) => {
      contactNavEL.addEventListener('click', () => {
        href = contactNavEL.getAttribute('data-location');
        Object.keys(names).forEach((nameKey) => {
          if (href === nameKey) {
            href = names[nameKey];
          }
        });
        assignHref(href);
      });
    });
  }
} catch (error) {
  console.log(error);
}
