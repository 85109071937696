const getUrlParameter = (sParam) => {
  try {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === 'undefined' ? true : sParameterName[1];
      }
    }
    return '';
  } catch (error) {
    console.log(error);
  }
};

export default getUrlParameter;
