/**
 * twig
 */
// RegEx: load only Twig-files NOT starting with an underscore (since those are partials)
// only use for frontend-dev and testing
const useTwig = () => {
  const requireAll = (req) => req.keys().map(req);
  requireAll(require.context('./twig', true, /(?:(?:(?:[/]?)(?:[^/]+\/)+)([^_]+\.twig))/));
};

useTwig();

/**
 * js
 */
import './js/tmp-fix-icon-links';
import './js/lazy-image';
import './js/isotope';
import './js/google-analytics';
import './js/europe-map';
import './js/header';
import './js/header-linkbar';
import './js/back-to-top';
import './js/contact';
import './js/piecharts';
import './js/sectorpie';
import './js/sectors';
import './js/submenu';
import './js/submenu-history-back';
import './js/read-more';
import './js/pr-contact';
import './js/newsletter';

/**
 * scss
 */
import './scss/main.scss';
