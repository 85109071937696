import EasyPieChart from 'easy-pie-chart';

let chartEls = null;

if (typeof websiteInit === 'undefined') {
  chartEls = document.querySelectorAll('.sectorpie-element');
}

const commonConfig = {
  scaleColor: false,
  trackColor: 'rgb(221,221,221)',
  lineWidth: 18,
  lineCap: 'butt',
  size: 150,
};

const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const chartEl = entry.target;
        const customColor = chartEl.getAttribute('data-color');
        const percentLabel = document.createElement('span');
        percentLabel.style.color = customColor;
        percentLabel.classList.add('showpercentage');
        chartEl.appendChild(percentLabel);

        let config = {
          ...commonConfig,
          onStep: function (from, to, percent) {
            percentLabel.innerHTML = Math.round(percent) + '%';
          },
        };

        config = { ...config, barColor: customColor };

        new EasyPieChart(chartEl, config);

        observer.unobserve(entry.target);
      }
    });
  },
  { rootMargin: '0px 0px 0px 0px', threshold: 0 }
);

try {
  chartEls.forEach((chartEl) => {
    observer.observe(chartEl);
  });
} catch (error) {
  console.log(error);
}
