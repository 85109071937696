import getUrlParameter from './get-url-parameter.js';
const submenuBackBtnEl = document.querySelector('.submenu-back');

try {
  document.querySelectorAll('[data-href-onclick-append]').forEach((link) => {
    const append = link.getAttribute('data-href-onclick-append');
    const href = link.getAttribute('href');
    if (append && href) {
      link.setAttribute('href', href + append);
    }
  });

  if (getUrlParameter('list') == 1 && history.length > 1 && submenuBackBtnEl) {
    submenuBackBtnEl.addEventListener('click', (e) => {
      e.preventDefault();
      history.go(-1);
    });
  }
} catch (error) {
  console.log(error);
}
