/**
 * This enhances the native html5 lazyload by adding
 * state representing classes to the assets container.
 * _lazyload.scss is needed for this to work properly.
 * @author Thomas Franz <thomas.franz@krankikom.de>
 * @version 1.1.1
 */

try {
  if ('loading' in HTMLImageElement.prototype && 'loading' in HTMLIFrameElement.prototype) {
    const lazyEls = document.querySelectorAll('.lazyload');

    const stateClass = {
      waiting: 'lazyload--waiting',
      error: 'lazyload--error',
      errorShow: 'lazyload--error-show',
      loaded: 'lazyload--loaded',
    };

    for (const lazyEl of lazyEls) {
      const lazyElAsset = lazyEl.querySelector('[loading="lazy"]');
      if (lazyElAsset) {
        lazyEl.lazyload = { state: 'waiting' };

        const onLoad = () => {
          lazyEl.classList.remove(stateClass.waiting);
          lazyEl.classList.add(stateClass.loaded);
          lazyEl.lazyload.state = 'loaded';
        };

        const onError = () => {
          lazyEl.classList.remove(stateClass.waiting);
          lazyEl.classList.add(stateClass.error);
          lazyEl.lazyload.state = 'error';
          setTimeout(() => {
            lazyEl.classList.add(stateClass.errorShow);
          }, 100);
        };

        const tagName = lazyElAsset.tagName;
        if (tagName === 'IFRAME') {
          lazyEl.classList.add(stateClass.waiting);
          lazyElAsset.onload = onLoad;
        } else if (tagName === 'IMG') {
          if (!lazyElAsset.complete) {
            lazyEl.classList.add(stateClass.waiting);
            lazyElAsset.addEventListener('load', onLoad, false);
            lazyElAsset.addEventListener('error', onError, false);
          }
        }
      }
    }
  }
} catch (error) {
  console.log(error);
}
