const headerEl = document.querySelector('.header');
const headerBackdropEl = document.querySelector('.header-backdrop');
const navEl = document.querySelector('.header__nav');
const menuBtnEl = document.querySelector('.header__menu-icon');
const stickyWrapperEl = document.querySelector('.sticky-wrapper');

const searchBtnEl = document.querySelector('.header__search__icon');
const searchFormEl = document.querySelector('.header__search__form');
const searchFormInputEl = document.querySelector('.header__search__form input');

const submenuEls = document.querySelectorAll('.header-submenu');
const submenuButtonEls = document.querySelectorAll('.header-submenu-button');

const lg = window.matchMedia('(min-width: 1024px)');

// searchbar interaction
try {
  if (headerEl && searchBtnEl && searchFormEl && searchFormInputEl) {
    const open = () => {
      searchBtnEl.classList.add('header__search__icon--active');
      searchFormEl.classList.add('header__search__form--active');
      searchFormInputEl.focus();
    };
    const close = () => {
      searchBtnEl.classList.remove('header__search__icon--active');
      searchFormEl.classList.remove('header__search__form--active');
    };

    searchBtnEl.addEventListener('click', () => {
      const isActive = searchFormEl.classList.contains('header__search__form--active');
      isActive ? close() : open();
    });

    if (menuBtnEl) {
      menuBtnEl.addEventListener('click', close);
    }
  }
} catch (error) {
  console.log(error);
}

try {
  // main menu interaction
  if (headerEl && menuBtnEl) {
    const openMenu = () => {
      if (!navEl.classList.contains('menu--active')) {
        navEl.classList.add('menu--active');
        menuBtnEl.classList.add('header__menu-icon--active');
        headerBackdropEl.classList.add('header-backdrop--active');
        document.querySelector('html').classList.add('overflow-stop');
      }
    };
    const closeMenu = () => {
      if (navEl.classList.contains('menu--active')) {
        navEl.classList.remove('menu--active');
        menuBtnEl.classList.remove('header__menu-icon--active');
        headerBackdropEl.classList.remove('header-backdrop--active');
        document.querySelector('html').classList.remove('overflow-stop');
      }
    };

    menuBtnEl.addEventListener('click', () => {
      if (navEl.classList.contains('menu--active')) {
        closeMenu();
      } else {
        openMenu();
      }
    });

    searchBtnEl.addEventListener('click', closeMenu);
    if (headerBackdropEl) headerBackdropEl.addEventListener('click', closeMenu);
  }
} catch (error) {
  console.log(error);
}

try {
  // header submenu interaction
  submenuEls.forEach((submenuEl) => (submenuEl.style.height = '0px'));
  submenuButtonEls.forEach((btnEl) => {
    btnEl.addEventListener('click', () => {
      const submenuEl = btnEl.parentNode.querySelector(':scope > .header-submenu');
      const submenuItemEls = submenuEl.querySelectorAll(':scope > li');
      const menuOpenHeight = submenuItemEls.length * 61 - 1;
      if (btnEl.classList.contains('header-submenu-button--active')) {
        btnEl.classList.remove('header-submenu-button--active');
        submenuEl.style.height = '0px';
      } else {
        btnEl.classList.add('header-submenu-button--active');
        submenuEl.style.height = menuOpenHeight + 'px';
      }
    });
  });
} catch (error) {
  console.log(error);
}

try {
  // sticky header
  if (stickyWrapperEl) {
    const decideIfSticky = () => {
      if (lg.matches && window.scrollY <= 20) {
        stickyWrapperEl.classList.remove('fixed');
      } else {
        stickyWrapperEl.classList.add('fixed');
      }
    };
    decideIfSticky();
    window.addEventListener('scroll', decideIfSticky);
  }
} catch (error) {
  console.log(error);
}
