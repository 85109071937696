try {
  document.querySelectorAll('.CMBOR .documentLink').forEach((linkEl) => {
    linkEl.setAttribute('data-tracklink', 1);
    linkEl.setAttribute('data-category', 'CMBR');
    linkEl.setAttribute('data-label', linkEl.parentNode.querySelector('.pdf-titel').innerHTML);
    linkEl.setAttribute('data-action', 'Download PDF');
    linkEl.setAttribute('data-value', '');
  });

  document.querySelectorAll('.AnnualReviews .documentLink').forEach((linkEl) => {
    linkEl.setAttribute('data-tracklink', 1);
    linkEl.setAttribute('data-category', 'AnnualReviews');
    linkEl.setAttribute('data-label', linkEl.parentNode.querySelector('.pdf-titel').innerHTML);
    linkEl.setAttribute('data-action', 'Download PDF');
    linkEl.setAttribute('data-value', '');
  });

  document.querySelectorAll('.AnnualReviews .externalLink').forEach((linkEl) => {
    linkEl.setAttribute('data-tracklink', 1);
    linkEl.setAttribute('data-category', 'AnnualReviews');
    linkEl.setAttribute('data-label', linkEl.parentNode.querySelector('.pdf-titel').innerHTML);
    linkEl.setAttribute('data-action', 'Flipbook');
    linkEl.setAttribute('data-value', '');
  });

  document.querySelectorAll('.investment-info a').forEach((linkEl) => {
    linkEl.setAttribute('data-tracklink', 1);
    linkEl.setAttribute('data-category', 'Investment Link');
    linkEl.setAttribute('data-label', linkEl.querySelector('img').getAttribute('alt'));
    linkEl.setAttribute('data-action', 'External Investment Link');
    linkEl.setAttribute('data-value', linkEl.getAttribute('href'));
  });

  document.querySelectorAll('.portfolio-news__item a').forEach((linkEl) => {
    linkEl.setAttribute('data-tracklink', 1);
    linkEl.setAttribute('data-category', 'Investment Portfolio News');
    linkEl.setAttribute('data-label', linkEl.parents('body').querySelector('h1').innerHTML);
    linkEl.setAttribute('data-action', 'Download PDF');
    linkEl.setAttribute('data-value', linkEl.getAttribute('href'));
  });
} catch (error) {
  console.log(error);
}
