const sectors = [
  {
    clickEL: '.consumerClickevent',
    areaEl: '.SectorConsumer',
  },
  {
    clickEL: '.financialClickevent',
    areaEl: '.SectorFinancial',
  },
  {
    clickEL: '.engineeringClickevent',
    areaEl: '.SectorSpecialist',
  },
  {
    clickEL: '.supportClickevent',
    areaEl: '.SectorSupport',
  },
];

try {
  sectors.forEach((sector) => {
    const clickEls = document.querySelectorAll(sector.clickEL);
    clickEls.forEach((clickEl) => {
      clickEl.addEventListener('click', () => {
        const areaEl = document.querySelector(sector.areaEl);
        if (areaEl) {
          const scrollVal = areaEl.getBoundingClientRect().top + window.scrollY - 300;
          window.scrollTo(0, scrollVal);
        }
      });
    });
  });

  const sectorAreaEls = document.querySelectorAll('.expander--button');
  sectorAreaEls.forEach((sectorAreaEl) => {
    const btnEl = sectorAreaEl.querySelector('.button');
    const expanderEl = sectorAreaEl.parentNode.querySelector('.expander--area');

    btnEl.addEventListener('click', (e) => {
      e.preventDefault();
      const expandedHeight = parseInt(
        expanderEl.querySelector(':scope > *').getBoundingClientRect().height
      );
      if (btnEl.classList.contains('expander-active')) {
        btnEl.classList.remove('expander-active');
        expanderEl.style.height = null;
        btnEl.innerHTML = 'show uk investments';
      } else {
        btnEl.classList.add('expander-active');
        expanderEl.style.height = expandedHeight + 'px';
        btnEl.innerHTML = 'close uk investments';
      }
    });
  });
} catch (error) {
  console.log(error);
}
