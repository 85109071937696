const iconEls = document.querySelectorAll('img[src*="sites/shared/relaunch/img/icons/"]');
const paths = ['/website/sites/shared/relaunch/img/icons/', '/sites/shared/relaunch/img/icons/'];

try {
  iconEls.forEach((iconEl) => {
    let src = iconEl.getAttribute('src');
    paths.forEach((path) => {
      if (src.includes(path)) {
        src = src.replace(path, '/assets/img/icons/');
      }
    });
    if (src.includes('icon_overview.png')) {
      src = src.replace('.png', '.svg');
    }
    iconEl.setAttribute('src', src);
  });
} catch (error) {
  console.log(error);
}
