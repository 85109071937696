import Isotope from 'isotope-layout';

const isotopeEl = document.querySelector('[data-isotope-instance]');
const filterEl = document.querySelector('form.filter');
const noResultEl = document.querySelector('[data-isotope-noresult]');
const lang = window.lang;

try {
  if (isotopeEl && filterEl) {
    const isotopeAdditionalConfig = (() => {
      const configAttr = isotopeEl.getAttribute('data-config');
      if (configAttr) {
        return JSON.parse(configAttr);
      }
      return {};
    })();

    const isotopeConfig = {
      itemSelector: '[data-isotope-item]',
      ...isotopeAdditionalConfig,
    };

    const iso = new Isotope(isotopeEl, isotopeConfig);
    const inputTextEls = filterEl.querySelectorAll('input[type=text]');

    const selectEls = filterEl.querySelectorAll('select');
    const selectCountryEl = filterEl.querySelector('#country');
    const selectHeadquarterEl = filterEl.querySelector('#headquarter');
    const selectSectorEl = filterEl.querySelector('#sector');
    const selectYearEl = filterEl.querySelector('#year');

    const inputRadioEls = filterEl.querySelectorAll('input[type=radio]');

    const observeEls = [...inputTextEls, ...selectEls, ...inputRadioEls];

    const restrictFiltersByMatchesPairs = [
      [selectCountryEl, selectHeadquarterEl],
      [selectCountryEl, selectSectorEl],
      [selectHeadquarterEl, selectSectorEl],
      [selectCountryEl, selectYearEl],
    ];

    const restrictFiltersByMatches = (pair, initElement) => {
      let check = true;
      const primarySelect = pair[0];
      const secondarySelect = pair[1];
      if (initElement && primarySelect) {
        if (initElement.name != primarySelect.name) {
          check = false;
        }
      }

      if (primarySelect && secondarySelect && primarySelect.name && secondarySelect.name && check) {
        const propName = `isotope-restrict-filters-${primarySelect.name}-${secondarySelect.name}`;
        // collect one-directional availabilty once per combo into a window object
        if (!window[propName]) {
          window[propName] = {};
          primarySelect.querySelectorAll('option').forEach((pOpt) => {
            if (pOpt.value !== 'all') {
              window[propName][pOpt.value] = [];
              secondarySelect.querySelectorAll('option').forEach((sOpt) => {
                const combinationEls = document.querySelectorAll(
                  `.${pOpt.value}.${sOpt.value}[data-isotope-item]`
                );
                if (combinationEls.length > 0) {
                  window[propName][pOpt.value].push(sOpt.value);
                }
              });
            }
          });
        }

        secondarySelect.querySelectorAll('option').forEach((sOpt) => {
          const primaryProp = window[propName][primarySelect.value];
          if (sOpt.value != 'all') {
            if (primaryProp) {
              const isAvailable = primaryProp.includes(sOpt.value);
              sOpt.disabled = isAvailable ? false : true;
            } else {
              sOpt.disabled = false;
            }
          }
        });
      }
    };

    if (selectCountryEl && lang !== 'en') {
      // if country filter available, preselect country by current site language unless it's EN
      selectCountryEl.value = 'country--' + lang;
    }

    restrictFiltersByMatchesPairs.forEach((pair) => {
      restrictFiltersByMatches(pair, filterEl.querySelector('#country'));
    });

    const filterFns = {
      inputsText(item) {
        const textEls = item.querySelectorAll('[data-text-search]');
        let results = [];
        inputTextEls.forEach((inputTextEl) => {
          let textElResults = [];
          textEls.forEach((textEl) => {
            if (textEl && inputTextEl) {
              const text = textEl.innerText.toLocaleLowerCase().trim();
              const search = inputTextEl.value.toLocaleLowerCase().trim();
              textElResults.push(text.includes(search) || search.length < 3 || text == '');
            }
          });
          results.push(textElResults.includes(true));
        });
        return !results.includes(false);
      },
      selects(item) {
        let results = [];
        selectEls.forEach((selectEl) => {
          results.push(item.classList.contains(selectEl.value) || selectEl.value == 'all');
        });
        return !results.includes(false);
      },
      inputsRadio(item) {
        let results = [];
        inputRadioEls.forEach((inputRadioEl) => {
          if (inputRadioEl.checked) {
            results.push(
              item.classList.contains(inputRadioEl.value) || inputRadioEl.value == 'all'
            );
          }
        });
        return !results.includes(false);
      },
    };

    const runFilter = () => {
      iso.arrange({
        filter: function (x, y) {
          const item = x || y;
          if (item) {
            return (
              filterFns.inputsText(item) && filterFns.selects(item) && filterFns.inputsRadio(item)
            );
          }
        },
      });
      if (noResultEl && iso.filteredItems.length == 0) {
        noResultEl.style.display = 'block';
      } else {
        noResultEl.style.display = 'none';
      }
    };

    observeEls.forEach((el) => {
      if (el.getAttribute('type') == 'text') {
        let timeout = null;
        el.addEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            runFilter();
            timeout = null;
            return null;
          }
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            runFilter();
            timeout = null;
          }, 750);
        });
      } else {
        el.addEventListener('change', () => {
          restrictFiltersByMatchesPairs.forEach((pair) => {
            restrictFiltersByMatches(pair, el);
          });
          runFilter();
        });
      }
    });

    window.addEventListener('load', () => {
      runFilter();
      setTimeout(runFilter, 1000); // edge did not filter correctly on history back
    });
  }
} catch (error) {
  console.log(error);
}
