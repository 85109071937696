const navEl = document.querySelector('.header__nav');
const navUlEl = document.querySelector('.header__nav > ul');
const navItemEls = document.querySelectorAll('.header__nav > ul > li');
const navBarEl = document.querySelector('.header__nav__active-bar');
const navActiveEl = document.querySelector('.header__nav--active');

const lg = window.matchMedia('(min-width: 1024px)');

const positionBarOn = (activeItem) => {
  if (lg.matches && activeItem) {
    const activeItemSpan = activeItem.querySelector(':scope > a > span');
    if (activeItemSpan) {
      const itemWidth = activeItemSpan.getBoundingClientRect().width;
      const itemOffsetLeft = activeItemSpan.getBoundingClientRect().left;
      const navOffsetLeft = navEl.getBoundingClientRect().left;
      navBarEl.style.left = itemOffsetLeft - navOffsetLeft + 'px';
      navBarEl.style.width = itemWidth + 'px';
    }
  }
};

try {
  if (lg.matches && navBarEl) {
    if (navActiveEl) {
      setTimeout(() => {
        positionBarOn(navActiveEl);
      }, 500);

      navItemEls.forEach((navItem) => {
        navItem.addEventListener('mouseover', () => {
          positionBarOn(navItem);
          navBarEl.classList.add('header__nav__active-bar--active');
        });
        navItem.addEventListener('mouseleave', () => {
          positionBarOn(navActiveEl);
          navBarEl.classList.remove('header__nav__active-bar--active');
        });
      });

      window.addEventListener('resize', () => {
        positionBarOn(navActiveEl);
      });
    } else {
      navItemEls.forEach((navItem) => {
        navItem.addEventListener('mouseover', () => {
          if (navBarEl.classList.contains('bar-fadeout')) {
            navBarEl.classList.remove('bar-fadeout');
          }
          navBarEl.classList.add('header__nav__active-bar--active');

          setTimeout(() => {
            if (!navEl.classList.contains('initialTrigger')) {
              navBarEl.classList.add('bar-animation-off');
            }
          }, 300);

          positionBarOn(navItem);

          if (!navEl.classList.contains('initialTrigger')) {
            setTimeout(() => {
              navBarEl.classList.remove('bar-animation-off');
            }, 10);
          }
          navEl.classList.add('initialTrigger');
        });
        navItem.addEventListener('mouseleave', () => {
          positionBarOn(navActiveEl);
          navBarEl.classList.remove('header__nav__active-bar--active');
        });
      });

      navUlEl.addEventListener('mouseleave', () => {
        navBarEl.classList.add('bar-fadeout');
        navBarEl.classList.remove('header__nav__active-bar--active');
        navEl.classList.remove('initialTrigger');
      });
    }
  }
} catch (error) {
  console.log(error);
}
