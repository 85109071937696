const isNotCms = !document.querySelector('body').classList.contains('iscms');
const prTextEl = document.querySelector('.press-releases-detail-text');
const prContectEl = document.querySelector('.pr-contact');

try {
  if (isNotCms && prTextEl && prContectEl) {
    const language = prTextEl.getAttribute('data-lang').toLowerCase();
    prContectEl.classList.add('pr-contact--' + language + '-only');
  }
} catch (error) {
  console.log(error);
}
