const form = document.querySelector('#mc_embed_signup form');
const formSubmitBtn = document.querySelector('#mc_embed_signup form #subscribe');
const lang = window.lang;
const url =
  lang == 'de'
    ? 'https://a69fe0d3.sibforms.com/serve/MUIEACG35ColtpsNtHq7w01Vi-U3YdnXnYjxAYsHGI7FFoa86i4lK7DK_KwjOQcLZuNBGMS7lfKVXqxWnTjcTMqDH2WX2Fde-As0HK-EfqpxGOMn_0A8BADb52e6dbdjC4fCudU9PsP_YtcNOKTfBeGzbwurDAQ0TxxLD622Ibz2SyHxtTmOaHCD_FP9z8eyGHYcVk1jbjFHKESr?isAjax=1'
    : 'https://a69fe0d3.sibforms.com/serve/MUIEAD7I9KnmF-qfzU2VhwVZqi3S6IqgY_6H7rAVOhVNpa2YWtsenKNdJ27CQ-JjtkFxmeHsU6UZieiDEqHEyeaSlvGExjuG36AYYRmdIv84x4gkzNChYWpk3bJxmmolxo0oNDmF5ISVwrzNa0-f7tQZf5_Epjye6YaDeXIJ6lawglz1Z6NcDJYH-wJNmvIqUWTzgSfAjS69qB1S?isAjax=1';

/*eslint-disable */
const isValidEmailAddress = (emailAddress) => {
  let pattern =
    /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
};
/*eslint-enable */

try {
  if (form && formSubmitBtn) {
    let v_errors = false;
    formSubmitBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const inlineErrorEls = form.querySelectorAll('.inline_error');
      const responseEls = form.querySelectorAll('.inline_error');
      const responseSuccess = document.querySelector('#success-response');
      const responseError = document.querySelector('#error-response');
      responseSuccess.style.display = 'none';
      responseError.style.display = 'none';

      form.addEventListener('click', () => {
        inlineErrorEls.forEach((inlineErrorEl) => (inlineErrorEl.style.display = 'none'));
        responseEls.forEach((responseEl) => (responseEl.style.display = 'none'));

        const v_vorname = form.querySelector('#VORNAME').value;
        if (v_vorname.length < 2) {
          v_errors = true;
          form.querySelector('#vorname_error').style.display = 'block';
        }

        const v_nachname = form.querySelector('#NACHNAME').value;
        if (v_nachname.length < 2) {
          v_errors = true;
          form.querySelector('#nachname_error').style.display = 'block';
        }

        const v_firma = form.querySelector('#FIRMA').value;
        if (v_firma.length < 2) {
          v_errors = true;
          form.querySelector('#firma_error').style.display = 'block';
        }

        const v_email = form.querySelector('#EMAIL').value;
        if (v_email.length < 2) {
          v_errors = true;
          form.querySelector('#email_error').style.display = 'block';
        } else {
          const v_valid = isValidEmailAddress(v_email);
          if (!v_valid) {
            v_errors = true;
            form.querySelector('#emailformat_error').style.display = 'block';
          }
        }

        if (!v_errors) {
          var http = new XMLHttpRequest();
          var myForm = new FormData();

          myForm.append('VORNAME', v_vorname);
          myForm.append('NACHNAME', v_nachname);
          myForm.append('FIRMA', v_firma);
          myForm.append('EMAIL', v_email);
          myForm.append('OPT_IN', 1);
          myForm.append('locale', lang);
          myForm.append('email_address_check', '');

          http.open('POST', url, true);

          http.onreadystatechange = function () {
            if (http.readyState == 4 && http.status == 200) {
              const response = JSON.parse(http.response);
              if (response.success) {
                form.reset();
                responseSuccess.style.display = 'block';
              } else {
                responseError.style.display = 'block';
              }
              return false;
            }
          };
          http.send(myForm);
        }

        return false;
      });
    });
  }
} catch (error) {
  console.log(error);
}
